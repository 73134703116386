<template>
  <div>
      <div id="backdrop" class="fixed top-0 left-0 w-full h-full bg-black bg-opacity-40 z-20" @click="$emit('exit')"></div>
      <div class="absolute h-full w-full top-0 left-0 z-30 ">
        <div class="w-full h-full flex items-center">
            <div class="p-10 bg-white rounded-lg mx-auto">
                <div class="flex flex-row items-center mb-4">
                    <h5 class="text-xl flex-auto font-bold mr-8">Sampler</h5>
                    <span class="text-red-400 hover:text-red-700 fas fa-times cursor-pointer" @click="$emit('exit')">x</span>
                </div>
                

                <div class=" mb-2">
                    <div v-for="region in regions" :ref="'region-'+regions.indexOf(region)" :key="regions.indexOf(region)" class="flex flex-row items-center text-white font-bold text-xs rounded-md p-1" :class="selected_sample === regions.indexOf(region) ? 'bg-teal-700' : 'bg-teal-500'" :style="{width: region_dsp[regions.indexOf(region)] + 'px', marginLeft: getRegionStart(region) + 'px'}">
                        <div class="p-2 cursor-pointer hover:bg-teal-300 rounded-md" @mousedown="startRegionResize(regions.indexOf(region), 'front', $event)" @mouseup="endRegionResize()"></div>
                        
                        <input v-if="editing_sample === regions.indexOf(region)" @keydown.enter="editing_sample = null" type="text" class="text-gray-600" v-model="region.sample">
                        <span @dblclick="editing_sample = regions.indexOf(region)" v-else>
                        {{ region.sample }}
                        </span>

                        <div class="flex-auto p-2 cursor-pointer" @click="selected_sample = regions.indexOf(region)"></div>
                        <div class="p-2 cursor-pointer hover:bg-teal-300 rounded-md" @mousedown="startRegionResize(regions.indexOf(region), 'back', $event)" @mouseup="endRegionResize()"></div>
                    </div>
                </div>
                <div class="flex-auto bg-red-500 p-3" @dblclick="createRegion()" v-if="!keyCoverage"></div>
                
                <div class="piano flex flex-row w-full" ref="pianobox">
                    <div v-for="i in 61" :key="i" class="text-xs text-gray-300 border pt-8 pb-2" :style="{width: keyWidth + '%'}">
                        {{i}}
                    </div>
                </div>
            </div>
        </div>
      </div>
  </div>
</template>

<script>
    let KEYS = 61;
    export default {
        props: {
            samples: Array,
            p_regions: Array,
        },
        data() {
            return {
                resize: {region: null},
                regions: this.p_regions,
                region_dsp: [],
                editing_sample: null,
                selected_sample: null
            }
        },
        mounted() {
            this.calcRegionPxSize();
        },
        methods: {
            clipKeySize(sz) {
                return (sz >= KEYS) * KEYS + (sz < KEYS) * sz;
            },
            calcRegionPxSize() {
                for(let i = 0; i < this.regions.length; i++) {
                    let region = this.regions[i];
                    this.region_dsp[i] = ((this.clipKeySize(region.key_end - region.key_start) * this.keyWidth) / 100) * this.maxWidth;
                }
            },
            getRegionStart(region) {
                if(this.$refs.pianobox) {
                    return this.keyPx * region.key_start;
                }
            },
            startRegionResize(region, side) {
                // TODO: implement two side resize
                this.resize.side = side;
                this.resize.region = region;
                document.onmousemove = this.regionResize;
            },
            regionResize(event) {
                // TODO: fix
                let key = (event.clientX - this.$refs["region-"+this.resize.region][0].getBoundingClientRect().left) / ((this.keyWidth / 100) * this.maxWidth);
                console.log(event.clientX, key, this.resize.side);
                if(this.resize.side === "back") {
                    this.regions[this.resize.region].key_end = this.regions[this.resize.region].key_start + Math.ceil(key);
                } else {
                    this.regions[this.resize.region].key_start = Math.floor(key);
                }
                this.calcRegionPxSize();
            },
            endRegionResize() {
                this.clearMouseEvents();
            },
            clearMouseEvents() {
                document.onmousemove = null
            },
            createRegion() {
                this.regions.push({
                    sample: 'default',
                    key_start: this.regions[this.regions.length - 1].key_end + 1,
                    key_end: KEYS - 1,
                    key_root: 50,
                    smp_start: 0,
                    smp_end: 0
                });
                this.calcRegionPxSize();
            },
            changeSample() {
                // TODO: implement sample selection
            }
        },
        computed: {
            keyWidth() {
                return 100/KEYS;
            },
            maxWidth() {
                    return this.$refs.pianobox.clientWidth;
            },
            keyPx() {
                return (this.keyWidth / 100) * this.maxWidth;
            },
            keyCoverage() {
                let sum = 0;
                for(let i = 0; i < this.regions.length; i++) {
                    sum += this.regions[i].key_end - this.regions[i].key_start;
                }
                return sum === KEYS;
            }
        }
    }
</script>

<style>

</style>