<template>
  <div class="h-full flex flex-col">
        <div class="flex-auto"></div>
        <div class="container mx-auto p-4 bg-white">
            <h4 class="font-bold text-xl">Samples</h4>

            <div>

            </div>

            <div class="piano flex flex-row">
                <div v-for="i in 61" :key="i" class="text-xs text-gray-300 flex-auto border pt-8 pb-2">
                    {{i}}
                </div>
            </div>
        </div>
        <div class="flex-auto"></div>
  </div>
</template>

<script>
    export default {
        props: {
            samples: Array
        }
    }
</script>

<style>

</style>